import './App.css';
import PocketBase from 'pocketbase';

import {
  BrowserRouter as Router,
  Routes,
  Route,

} from 'react-router-dom';

import ForgotPassword from './components/forgot-password';
import ConfirmVerification from './components/confirm-verification';

function App() {

  const client = new PocketBase('https://doctris-cloud.koreacentral.cloudapp.azure.com');
  client.realtime.subscribe('blog', function (e) {
      if (e.action === 'create') {
        const url = 'https://fcm.googleapis.com/fcm/send';
        const data = {
          "to": "c1wx-3sOS9ihJC4adSkH97:APA91bHAv8XHG7B0GcvYA8iEWNjqlRFlG9yZjEAqx7ejFKgNM_pKirwtyrYNRMwgnW7jA0SMTJDE8g7vRXUgUzDxPQUCtC9h8KkfBFJiqY_50292KMfLEHwsKx33P1fHm19xmlMCID8n",
          "notification": {
            "title": e.record.title,
            "body": e.record.title,
          },
        };
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': "key=AAAAxqzJAu8:APA91bHBc9AMn5dAF5QRYGSmR1V7nN1FULL0WDGuJJDw5SzIPjIBoBCrDrmswV9et3A7EtjTp668fuQNmwMISFwpDwmQYdE_kGhlkW6IM2jCsAlOG7sJpP_P100SiuJd9_hjDRMU55Qk"
        };
    
        fetch(url, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(data),
          referrerPolicy: "unsafe_url" 
        }).then(function (response) {
          console.log(response);
        }
        ).catch(function (error) {
          console.log(error);
        }
        );
      }
  });

  return (
    <Router>
      <Routes>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/confirm-verification" element={<ConfirmVerification />} />
      </Routes>
    </Router>
  );
}

export default App;
